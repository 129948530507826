import React from 'react'
import Layout from '../components/layout'
import ImageTextBlock from '../components/imageTextBlock/imageTextBlockView'
import { graphql} from 'gatsby'





const RestaurantIndex = (props) => {

  const textBlokken = props.data.allContentfulRestaurantPage.nodes[0].imageTextBlocks;
  const menukaart = props.data.allContentfulRestaurantPage.nodes[0].menu;


  console.log(menukaart);



    return (
      <Layout title={props.data.allContentfulRestaurantPage.nodes[0].title} location={props.location}>
        <div className='empty_box'></div>
        <h1 style={{textAlign: 'center'}} >{props.data.allContentfulRestaurantPage.nodes[0].title}</h1>
        <h3 style={{textAlign: 'center', color: '#fff', margin: '0px 15px 50px'}} >{props.data.allContentfulRestaurantPage.nodes[0].subHeading}</h3>
        <div style={{display: 'flex', flexWrap: 'wrap', maxWidth: 'var(--size-max-width)', justifyContent: 'space-between', margin: '0 auto' }}>
            {(textBlokken).map((blok) => <ImageTextBlock key={blok.heading} image={blok.image?.gatsbyImageData} heading={blok.heading} small={!blok.largeBlock} content={blok.text}/> )  }
        </div>

        <div style={{height: '100px'}}></div>
        <h2 style={{textAlign: 'center'}}>Menukaart</h2>
        <div className='mobile_column' style={{display: 'flex', flexWrap: 'wrap', maxWidth: 'var(--size-max-width)', justifyContent: 'space-between', margin: '0 auto' , backgroundColor: '#2b2b2f' }}>
          <div className='column half_width mobile_row' style={{padding: '0px 80px'}}>
            <h2>Warme Dranken</h2>
            {(menukaart.warmeDranken).map((product) => 
                <div key={product.product} className='row' style={{justifyContent: 'space-between', width: '100%',padding: '0px 15px', lineHeight: '5px'}}>
                  <p>{product.product}</p>
                  <p>{product.prijs}</p>
                </div>
            )}
            <h2>Koude Dranken</h2>
            {(menukaart.koudeDranken).map((product) => 
                <div key={product.product} className='row' style={{justifyContent: 'space-between', width: '100%',padding: '0px 15px', lineHeight: '5px'}}>
                  <p>{product.product}</p>
                  <p>{product.prijs}</p>
                </div>
            )}
            <h2>Bieren van de tap</h2>
            {(menukaart.bierenTap).map((product) => 
                <div key={product.product} className='row' style={{justifyContent: 'space-between', width: '100%',padding: '0px 15px', lineHeight: '5px'}}>
                  <p>{product.product}</p>
                  <p>{product.prijs}</p>
                </div>
            )}
            <h2>Bieren van de fles</h2>
            {(menukaart.bierenFles).map((product) => 
                <div key={product.product} className='row' style={{justifyContent: 'space-between', width: '100%',padding: '0px 15px', lineHeight: '5px'}}>
                  <p>{product.product}</p>
                  <p>{product.prijs}</p>
                </div>
            )}
            <h2>Wijnen (wit)</h2>
            {(menukaart.wjinenWit).map((product) => 
                <div key={product.product} className='row' style={{justifyContent: 'space-between', width: '100%',padding: '0px 15px', lineHeight: '5px'}}>
                  <p>{product.product}</p>
                  <p>{product.prijs}</p>
                </div>
            )}
            <h2>Wijnen (rood)</h2>
            {(menukaart.wijnenRood).map((product) => 
                <div key={product.product} className='row' style={{justifyContent: 'space-between', width: '100%',padding: '0px 15px', lineHeight: '5px'}}>
                  <p>{product.product}</p>
                  <p>{product.prijs}</p>
                </div>
            )}
            <h2>Champagne</h2>
            {(menukaart.champagne).map((product) => 
                <div key={product.product} className='row' style={{justifyContent: 'space-between', width: '100%',padding: '0px 15px', lineHeight: '5px'}}>
                  <p>{product.product}</p>
                  <p>{product.prijs}</p>
                </div>
            )}

          </div>
          <div className='column half_width mobile_row' style={{padding: '0px 80px'}}>
          <h2>Warm uit de oven</h2>
            {(menukaart.snackWarm).map((product) => 
                <div key={product.product} className='row' style={{justifyContent: 'space-between', width: '100%',padding: '0px 15px', lineHeight: '5px'}}>
                  <p>{product.product}</p>
                  <p>{product.prijs}</p>
                </div>
            )}
            <h2>Snacks</h2>
            {(menukaart.snacks).map((product) => 
                <div key={product.product} className='row' style={{justifyContent: 'space-between', width: '100%',padding: '0px 15px', lineHeight: '5px'}}>
                  <p>{product.product}</p>
                  <p>{product.prijs}</p>
                </div>
            )}
            <h2>Broodjes</h2>
            {(menukaart.broodjes).map((product) => 
                <div key={product.product} className='row' style={{justifyContent: 'space-between', width: '100%',padding: '0px 15px', lineHeight: '5px'}}>
                  <p>{product.product}</p>
                  <p>{product.prijs}</p>
                </div>
            )}
            <h2>Combi</h2>
            {(menukaart.combi).map((product) => 
                <div key={product.product} className='row' style={{justifyContent: 'space-between', width: '100%',padding: '0px 15px', lineHeight: '5px'}}>
                  <p>{product.product}</p>
                  <p>{product.prijs}</p>
                </div>
            )}
            <h2>Borrelhapjes</h2>
            {(menukaart.borrelhapjes).map((product) => 
                <div key={product.product} className='row' style={{justifyContent: 'space-between', width: '100%',padding: '0px 15px', lineHeight: '5px'}}>
                  <p>{product.product}</p>
                  <p>{product.prijs}</p>
                </div>
            )}
            <h2>Snoep</h2>
            {(menukaart.snoep).map((product) => 
                <div key={product.product} className='row' style={{justifyContent: 'space-between', width: '100%',padding: '0px 15px', lineHeight: '5px'}}>
                  <p>{product.product}</p>
                  <p>{product.prijs}</p>
                </div>
            )}



          </div>
        </div>


        


      </Layout>

    )
  }


export default RestaurantIndex


export const PageQuery = graphql`
query RestaurantQuery {
  allContentfulRestaurantPage {
    nodes {
      title
      subHeading
      imageTextBlocks {
        image {
          gatsbyImageData
        }
        heading
        text {
          raw
        }
        largeBlock
      }
      menu {
        bierenFles {
          prijs
          product
        }
        bierenTap {
          product
          prijs
        }
        borrelhapjes {
          prijs
          product
        }
        broodjes {
          prijs
          product
        }
        champagne {
          product
          prijs
        }
        combi {
          prijs
          product
        }
        koudeDranken {
          prijs
          product
        }
        snackWarm {
          prijs
          product
        }
        snacks {
          prijs
          product
        }
        snoep {
          prijs
          product
        }
        warmeDranken {
          prijs
          product
        }
        wijnenRood {
          product
          prijs
        }
        wjinenWit {
          prijs
          product
        }
      }
    }
  }
}
`


